import { isDev } from 'utils/utils'

export const META_PIXEL_ID = 4871029359670443

export const pageview = () => {
  if (isDev) return
  // @ts-ignore
  window.fbq('track', 'PageView')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
  if (isDev) return
  // @ts-ignore
  window.fbq('track', name, options)
}
