import { captureException } from '@sentry/nextjs'
import * as dateFns from 'date-fns'
import { format, isPast, isValid, parseISO, startOfDay } from 'date-fns'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { createContext, useEffect, useReducer } from 'react'
import { BookingDuration } from 'types/types'

export type Action =
  | { type: 'setDate'; payload: string }
  | { type: 'setMonth'; payload: string }
  | { type: 'setTime'; payload: string | null }
  | { type: 'setDuration'; payload: BookingDuration }
  | { type: 'setState'; payload: State }

type Reducer = (state: State, action: Action) => State

interface State {
  date?: string
  month?: string
  time?: string | null
  duration?: BookingDuration
}

interface Store {
  state: State
  dispatch: React.Dispatch<React.ReducerAction<Reducer>>
}

const tomorrow = dateFns.addDays(new Date(), 1)

const initialState: Store = {
  state: {
    date: format(tomorrow, 'yyyy-MM-dd'),
    month: format(tomorrow, 'yyyy-MM'),
    time: undefined,
    duration: undefined
  },
  dispatch: () => {
    console.error('dispatch not in scope')
    captureException(new Error('dispatch not in scope'))
    return undefined
  }
}

export const StateContext = createContext<Store>(initialState)

type Props = {
  children?: React.ReactNode
}
export const StateProvider: React.FC<Props> = ({ children }) => {
  const [storedState, storeState] = useLocalStorage<State>(
    'state',
    initialState.state
  )
  const [state, dispatch] = useReducer((prevState: State, action: Action) => {
    switch (action.type) {
      case 'setDate': {
        const newState: State = {
          ...prevState,
          date: action.payload
        }
        storeState(newState)
        return newState
      }
      case 'setMonth': {
        const newState: State = {
          ...prevState,
          month: action.payload
        }
        storeState(newState)
        return newState
      }
      case 'setTime': {
        const newState: State = {
          ...prevState,
          time: action.payload
        }
        storeState(newState)
        return newState
      }
      case 'setDuration': {
        const newState: State = {
          ...prevState,
          duration: action.payload
        }
        storeState(newState)
        return newState
      }
      case 'setState': {
        const newState: State = {
          ...prevState,
          ...action.payload
        }
        storeState(newState)
        return newState
      }
    }
  }, storedState)
  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  )
}

export const useInitialStateFromLocalStorage = (
  dispatch: (value: Action) => void
) => {
  useEffect(() => {
    const today = new Date()
    // Get initial state from local storage
    try {
      const item = window.localStorage.getItem('state')
      if (!item) return
      // TODO: Validate data
      // TODO: If date / month is in the past, then clear date / month
      const parsed = JSON.parse(item)
      if (!isValid(parseISO(parsed.month))) {
        delete parsed.month
      }
      if (
        parsed.month &&
        isValid(parseISO(parsed.month)) &&
        isPast(parseISO(parsed.month))
      ) {
        parsed.month = format(today, 'yyyy-MM')
      }
      if (!isValid(parseISO(parsed.date))) {
        delete parsed.date
      }
      if (
        parsed.date &&
        isValid(parseISO(parsed.date)) &&
        isPast(parseISO(parsed.date))
      ) {
        parsed.date = startOfDay(today)
        parsed.month = format(today, 'yyyy-MM')
      }
      // Fixes case where user has selected date in different month and different month is stored in local storage
      // Without this check we would fetch wrong months timeslots and show no available timeslots for user to select
      if (
        parsed.date &&
        parsed.month &&
        isValid(parseISO(parsed.month)) &&
        isValid(parseISO(parsed.date)) &&
        parsed.month !== format(new Date(parsed.date), 'yyyy-MM')
      ) {
        parsed.month = format(new Date(parsed.date), 'yyyy-MM')
      }
      dispatch({ type: 'setState', payload: parsed })
    } catch (error) {
      console.error(error)
      captureException(error)
    }
  }, [dispatch])
}
