import DefaultLayout from 'components/layouts/default'
import { Grommet, GrommetProps } from 'grommet'
import Head from 'next/head'
import 'pages/styles.css'
import 'reset-css'
import { blue, blueText, gray } from 'utils/colors'
import Script from 'next/script'
import { isDev } from 'utils/utils'
import { META_PIXEL_ID } from 'lib/metapixel'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import * as fbq from 'lib/metapixel'

const theme: GrommetProps['theme'] = {
  global: {
    colors: { brand: '#000', gray, focus: blue },
    font: {
      family: 'Roboto'
    },
    breakpoints: {
      medium: { value: 1150 }
    },
    focus: {
      outline: {
        size: '0'
      }
    }
  },
  text: {
    // @ts-ignore
    font: {
      family: 'Roboto'
    }
  },

  formField: {
    border: false,
    label: {
      margin: { horizontal: '0' }
    },
    focus: {
      background: 'red'
    }
  },
  textInput: {
    extend: () => `
      font-family: Roboto;
      background-color: white;
      border: 2px solid #CCC;
      &:focus {
        border-color: ${blue};
      }
    `
  },
  heading: {
    color: blueText,
    font: {
      family: 'Ultra'
    },
    level: {
      1: {
        font: { weight: 'normal' }
      },
      4: {
        medium: {
          size: '26px',
          height: '40px',
          maxWidth: '816px'
        }
      },
      5: {
        medium: {
          size: '22px'
        }
      }
    },
    responsiveBreakpoint: undefined, // do not apply style from smaller level on mobile (small)
    extend: props => {
      if (props.level === 1) {
        return `
          font-size: 48px !important;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
      `
      }
      if (props.level === 4) {
        return `
          line-height: 34px;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
        `
      }
      return `
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
      `
    }
  },
  anchor: {
    hover: {
      textDecoration: 'none',
      extend: _props => {
        return `
          color: ${blueText};
        `
      }
    }
  },
  notification: {
    toast: {
      time: 8000 // 8 seconds

      // 86400000 // never timeout
    }
  }
}

export enum BreakPointSizes {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

function MyApp({ Component, pageProps }) {
  const Layout = Component.Layout || DefaultLayout
  const router = useRouter()

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview()

    const handleRouteChange = () => {
      fbq.pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <Grommet plain theme={theme}>
      <Head>
        <title>Vuokraa vene Helsingistä (Hakaniemi, Helsinki)</title>
        <meta
          name="description"
          content="Vuokraa sähkövene tunniksi tai vaikka koko päiväksi Helsingin Hakaniemestä ja ole itse veneen kapteeni! Max. 8 hlö per vene. Vuokraa vene täältä »"
        />
        <link rel="icon" href="favicon.svg" />
        <link rel="icon" sizes="48x48" href="favicon-32.png" type="image/png" />
        <link rel="icon" sizes="32x32" href="favicon-48.png" type="image/png" />
        <link rel="mask-icon" href="favicon.svg" color="#2546a3" />
        <link rel="apple-touch-icon" href="favicon-apple-touch.png" />
        <link rel="manifest" href="manifest.json" />
        <meta
          name="facebook-domain-verification"
          content="0lobtne4k8c2sbogw29hlcbipdvv1r"
        />
      </Head>
      <Layout>
        {isDev ? null : (
          <>
            <Script
              id="ms-clarity" // clarity.microsoft.com
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                (function (c, l, a, r, i, t, y) {
                  c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
                  t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
                  y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
                })(window, document, "clarity", "script", "h9gish6wyf");`
              }}
            />
            <Script
              strategy="afterInteractive"
              src="https://www.googletagmanager.com/gtag/js?id=G-9D3K49G8TJ"
            />
            <Script
              id="gtag"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', 'G-9D3K49G8TJ');
                `
              }}
            />
            <Script
              id="meta-pixel"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                  <!-- Meta Pixel Code -->
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', ${META_PIXEL_ID});
                  <!-- End Meta Pixel Code -->
                `
              }}
            />
          </>
        )}

        <Component {...pageProps} />
      </Layout>
    </Grommet>
  )
}

export default MyApp
