import _ from 'lodash'
import util from 'util'
import { BookingDuration } from 'types/types'

export function durationToNumber(duration: BookingDuration): number {
  switch (duration) {
    case BookingDuration.four:
      return 4
    case BookingDuration.three:
      return 3
    case BookingDuration.two:
      return 2
    case BookingDuration.one:
      return 1
  }
}

export const slotsInHour = ['00']
export const allPossibleHours = (duration: BookingDuration): string[] => {
  switch (duration) {
    case BookingDuration.four:
      return _.range(10, 19).flatMap(i => slotsInHour.map(min => `${i}:${min}`))

    case BookingDuration.three:
      return _.range(10, 20).flatMap(i => slotsInHour.map(min => `${i}:${min}`))

    case BookingDuration.two:
      return _.range(10, 20).flatMap(i => slotsInHour.map(min => `${i}:${min}`))

    case BookingDuration.one:
      return _.range(10, 20).flatMap(i => slotsInHour.map(min => `${i}:${min}`))
  }
}

const maxEndTime = 22
export const formatTime = ({
  duration,
  time
}: {
  duration?: BookingDuration
  time?: string | null
}) => {
  if (!time || !duration) return '-'
  const startTime = time.slice(0, 2)
  const endTime = Math.min(
    parseInt(time.slice(0, 2)) + durationToNumber(duration),
    maxEndTime
  )
  return `${startTime}-${endTime}`
}

export function durationToPrice(duration: BookingDuration): number {
  switch (duration) {
    case BookingDuration.four:
      return 150
    case BookingDuration.three:
      return 130
    case BookingDuration.two:
      return 100
    case BookingDuration.one:
      return 80
  }
}

export function hoursToDuration(hours: number): BookingDuration {
  switch (hours) {
    case 4:
      return BookingDuration.four
    case 3:
      return BookingDuration.three
    case 2:
      return BookingDuration.two
    case 1:
      return BookingDuration.one
    default:
      throw new Error(`Invalid hours: ${hours}`)
  }
}

export const getTitle = (duration: BookingDuration) => {
  const titlePartI = 'Vuokraa vene'
  const titlePartII = 'tunniksi käyttöösi'
  switch (duration) {
    case BookingDuration.one:
      return `${titlePartI} ${titlePartII}`
    case BookingDuration.two:
      return `${titlePartI} 2 ${titlePartII}`
    case BookingDuration.three:
      return `${titlePartI} 3 ${titlePartII}`
    case BookingDuration.four:
      return `${titlePartI} 4 ${titlePartII}`
  }
}

export const isDev = process && process.env.NODE_ENV === 'development'

// system variables needs to be exposed (tick box in Vercel)
export const isPreview = process && process.env.VERCEL_ENV === 'preview'
export const isProd = process && process.env.VERCEL_ENV === 'production'

export const stripeApiVersion = '2022-11-15'

export const inspect = (obj: object) => util.inspect(obj, true, null, true)
